<template>
<div>
    <div class="h-handle-bg">
        <div class="h-handle-button">
            <p class="h-return">
                <el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
            </p>
            <div class="h-b">
                <el-button type="primary" size="small" v-right-code="'Product:Edit'" @click="addProductInfo">新增</el-button>
            </div>
            <div class="h-b">
                <el-button type="primary" size="small" :disabled="isDisabled" v-right-code="'Product:Edit'" @click="saveProductInfo">保存
                </el-button>
            </div>
        </div>
    </div>
    <el-form :model="dataSource" ref="_productInfoForm" :rules="productInfoValidate">
        <el-tabs v-model.trim="activeName" type="border-card">
            <el-tab-pane name="first" label="基本信息">
                <h3 class="header_p">自定义属性</h3>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>货主名称：</el-col>
                    <el-col :span="7">
                        <el-form-item>
                            <ChooseCustomer v-model="dataSource.CustomerCode" :customerName.sync="dataSource.CustomerName" :onChange="customerChange"></ChooseCustomer>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>商品编码：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductNo">
                            <el-input v-model.trim="dataSource.ProductNo" :maxlength="30" :minlength="4" placeholder="不填则自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">商品内码：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="InternalCode">
                            <el-input v-model.trim="dataSource.InternalCode" :disabled="true" :maxlength="30" :minlength="4" placeholder="自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">商品条码：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductCode">
                            <el-input v-model.trim="dataSource.ProductCode" :maxlength="200" :minlength="4" placeholder="拼音码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>商品名称：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductName">
                            <el-input v-model.trim="dataSource.ProductName" :disabled="dataSource.ProductStatus==100" :maxlength="200" :minlength="4" placeholder="商品名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">商品英文名称：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductShortName">
                            <el-input v-model.trim="dataSource.ProductEnglishName" :maxlength="200">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">商品通用名称：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductCommonName">
                            <el-input v-model.trim="dataSource.ProductCommonName" :maxlength="200" placeholder="商品通用名称"></el-input>
                        </el-form-item>
                    </el-col>
                        <el-col :span="3" class="form-title">商品描述：</el-col>
                        <el-col :span="7">
                            <el-form-item prop="ProductMemo">
                                <el-input maxlength="50" show-word-limit :autosize="{ minRows: 4, maxRows: 4}" v-model.trim="dataSource.ProductMemo">
                                </el-input>
                            </el-form-item>
                        </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">拼音码：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductName">
                            <el-input v-model.trim="dataSource.PinYinCode" :maxlength="200" :minlength="4" placeholder="拼音码"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="3" class="form-title">外部编码：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="OuterID">
                            <el-input v-model.trim="dataSource.OuterID" :disabled="dataSource.ProductStatus!=null" :maxlength="30" :minlength="1"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="3" class="form-title">助记码：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductName">
                            <el-input v-model.trim="dataSource.MnemonicCode" :maxlength="200" :minlength="4" placeholder="助记码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>生产厂家：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="Manufacturer">
                            <el-input v-model.trim="dataSource.Manufacturer" :maxlength="30" :minlength="1">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">厂家地址：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ManufacturerAddress">
                            <el-input v-model.trim="dataSource.ManufacturerAddress" :maxlength="250" :minlength="1">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">产地：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="OriginArea">
                            <el-input v-model.trim="dataSource.OriginArea" :maxlength="30" :minlength="1">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>商品分类：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductTypeCode">
                            <el-cascader v-model="dataSource.ProductTypeCode" @change="classifyrChange(dataSource.ProductTypeCode)" :options="classfiySelectOptions" :props="{ checkStrictly: true,emitPath:false }" clearable filterable></el-cascader>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">品牌：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductBrand">
                            <el-input v-model.trim="dataSource.ProductBrand" :maxlength="30" :minlength="1">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">供应商：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="SupplierName">
                            <el-input v-model.trim="dataSource.SupplierName" :maxlength="30" :minlength="1">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">商品规格：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="SKU">
                            <el-input v-model.trim="dataSource.SKU" :maxlength="200" placeholder="商品规格"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="3" class="form-title">保质期(天)：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ValidDay">
                            <el-input type="number" oninput="this.value=this.value.replace(/[^0-9]+/,'');" v-model.trim.number="dataSource.ValidDay" placeholder="有效期">
                                <template slot="append">
                                    天
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <!-- <el-form-item>
                    <el-col :span="3" class="form-title">是否防窜：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsPreventTamper">
                            <el-switch v-model.trim="dataSource.ProductExtend.IsPreventTamper"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>是否冷藏品：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsFrozen">
                            <el-switch v-model.trim="dataSource.ProductExtend.IsFrozen"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-form-item> -->
                <el-form-item>
                    <el-col :span="3" class="form-title">是否标品：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsStandard">
                            <el-switch v-model.trim="dataSource.IsStandard"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">易碎品：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsFragile">
                            <el-switch v-model.trim="dataSource.IsFragile"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">危险品：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsDanger">
                            <el-switch v-model.trim="dataSource.IsDanger"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">管理模式：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ManageModel">
                            <DictionarySelect v-model="dataSource.ManageModel" datType='Int'
										dicTypeCode="ManageModelCode">
									</DictionarySelect>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">状态：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductStatus">
                            <DictionarySelect v-model="dataSource.ProductStatus" dicTypeCode="ProductStatusCode"
											datType="Int"></DictionarySelect>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">是否赠品：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsGift">
                            <el-switch v-model.trim="dataSource.IsGift"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">采购价(元)：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="PurchasePrice">
                            <el-input v-model.trim="dataSource.PurchasePrice">
                                <template slot="append">
                                    元
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">销售价(元)：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="SalePrice">
                            <el-input v-model.trim="dataSource.SalePrice">
                                <template slot="append">
                                    元
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                <el-col :span="3" class="form-title"><span style="color:red;">*</span>主单位：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ProductWholeUnit">
                            <el-input v-model.trim="dataSource.ProductWholeUnit" :disabled="dataSource.ProductStatus==100" :maxlength="30" :minlength="4" placeholder="主单位："></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <!--医药扩展字段-->
                <h3 class="header_p">医药行业属性</h3>
                <el-form-item>
                    <template v-for="(item,index) in dataSource.ConfigList">
                        <el-col :span="3" class="form-title extend-title" :key="'t-'+item.Id"><span style="color:red;" v-if="item.Required">*</span>{{item.FieldLabel}}：</el-col>
                        <el-col :span="4" class="extend-content" :key="'c-'+item.Id">
                            <el-form-item :prop="'ProductExtend.'+item.FieldName" :rules="productInfoValidate.ProductExtend[item.FieldName]">
                                <template v-if="item.FieldName=='DosageForm'">
                                    <DictionarySelect v-model="dataSource.ProductExtend.DosageForm" dicTypeCode="DosageFormTypeCode"></DictionarySelect>
                                </template>
                                <template v-else>
                                    <el-switch v-if="item.FieldType=='bool'" v-model.trim="dataSource.ProductExtend[item.FieldName]"></el-switch>
                                    <el-input v-else v-model.trim="dataSource.ProductExtend[item.FieldName]" :maxlength="100" :minlength="1" @blur="(e) => (dataSource.ProductExtend[item.FieldName] = e.target.value)"></el-input>
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col class="extend-content" :key="'e-'+item.Id" :span="3" v-if="(index+1)%3==0">&nbsp;</el-col>
                    </template>
                </el-form-item>

                <h3 class="header_p">仓储物流信息</h3>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>存储条件：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="StorageConditonName">
                            <DictionarySelect v-model="dataSource.StorageConditonName" dicTypeCode="StorageConditonCode"></DictionarySelect>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">是否小数控制：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="IsOrderDecimal">
                            <el-switch v-model.trim="dataSource.IsOrderDecimal"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">ABC分类：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ABCType">
                            <el-select v-model.trim="dataSource.ABCType" :maxlength="200">
                                <el-option v-for="item in ABCTypeList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">运输注意事项：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ShippingAttention">
                            <el-input v-model.trim="dataSource.ShippingAttention" :maxlength="10">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="3" class="form-title">是否双人质检：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsDoubleQc">
                            <el-switch v-model.trim="dataSource.ProductExtend.IsDoubleQc"></el-switch>
                        </el-form-item>
                    </el-col> -->
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">运输方式：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ShippingType">
                            <DictionarySelect v-model="dataSource.ShippingType" dicTypeCode="ShippingTypeCode"></DictionarySelect>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">是否双人复核：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsDoubleCheck">
                            <el-switch v-model.trim="dataSource.ProductExtend.IsDoubleCheck"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">运输温层：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="ShippingTemperature">
                            <el-input v-model.trim="dataSource.ShippingTemperature" :maxlength="10">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">仓储温层：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="StorageTemperature">
                            <el-input v-model.trim="dataSource.StorageTemperature" :maxlength="10">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">批次管理：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsBatchManage">
                            <el-switch v-model.trim="dataSource.IsBatchManage"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">有效期管理：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsValidManage">
                            <el-switch v-model.trim="dataSource.IsValidManage"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">拆分粒度：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="SplitSize">
                            <el-input v-model.trim="dataSource.SplitSize" :maxlength="10">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">存储注意事项：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="StorageAttention">
                            <el-input v-model.trim="dataSource.StorageAttention" :maxlength="10">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <!-- <el-form-item>
                    <el-col :span="3" class="form-title">毛重：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="GrossWeight">
                            <el-input type="number" oninput="this.value=this.value.replace(/[^0-9.]+/,'');" v-model.trim.number="dataSource.GrossWeight">
                                <template slot="append">
                                    kg
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">净重：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="NetWeight">
                            <el-input type="number" oninput="this.value=this.value.replace(/[^0-9.]+/,'');" v-model.trim.number="dataSource.NetWeight">
                                <template slot="append">
                                    kg
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">体积：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="ProductVolume">
                            <el-input type="number" oninput="this.value=this.value.replace(/[^0-9.]+/,'');" v-model.trim.number="dataSource.ProductVolume">
                                <template slot="append">
                                    cm³
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">长：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="ProductLong">
                            <el-input type="number" @change="VolumeCalculation" oninput="this.value=this.value.replace(/[^0-9.]+/,'');" v-model.trim.number="dataSource.ProductLong">
                                <template slot="append">
                                    cm
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">宽：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="ProductWidth">
                            <el-input type="number" @change="VolumeCalculation" oninput="this.value=this.value.replace(/[^0-9.]+/,'');" v-model.trim.number="dataSource.ProductWidth">
                                <template slot="append">
                                    cm
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">高：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="ProductHeight">
                            <el-input type="number" @change="VolumeCalculation" oninput="this.value=this.value.replace(/[^0-9.]+/,'');" v-model.trim.number="dataSource.ProductHeight">
                                <template slot="append">
                                    cm
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>序列号管理：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="IsSerialNumManage">
                            <el-switch v-model.trim="dataSource.IsSerialNumManage"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">自定义分类：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="CustomClassifiy">
                            <el-input v-model.trim="dataSource.CustomClassifiy" :disabled="true" :maxlength="30" :minlength="1">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">临期阈值：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="AdventThresholdValue">
                            <el-input v-model.trim="dataSource.AdventThresholdValue" :maxlength="30" :minlength="1">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">临期比例(%)：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="AdventProportion">
                            <el-input-number v-model.trim="dataSource.AdventProportion" :precision="2" :step="0.1" :max="10"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">有效期禁收：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="ValidBanReceive">
                            <el-input type="number" oninput="this.value=this.value.replace(/[^0-9]+/,'');" v-model.trim.number="dataSource.ValidBanReceive" placeholder="有效期禁收">
                                <template slot="append">
                                    天
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">有效期禁售：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="ValidBanSale">
                            <el-input type="number" oninput="this.value=this.value.replace(/[^0-9]+/,'');" v-model.trim.number="dataSource.ValidBanSale" placeholder="有效期禁售">
                                <template slot="append">
                                    天
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">码板标准：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="CodePlateStandard">
                            <el-input v-model.trim="dataSource.CodePlateStandard" :maxlength="30" :minlength="1">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                        <el-col :span="3" class="form-title">一级分类：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="CategoryOne">
                                <el-input v-model.trim="dataSource.CategoryOne" :maxlength="30" :minlength="1">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">二级分类：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="CategoryTwo">
                                <el-input v-model.trim="dataSource.CategoryTwo" :maxlength="30" :minlength="1">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">三级分类：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="CategoryThree">
                                <el-input v-model.trim="dataSource.CategoryThree" :maxlength="30" :minlength="1">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                <el-form-item>

                    <el-col :span="3" class="form-title">成本价(元)：</el-col>
                    <el-col :span="4">
                        <el-form-item prop="CostPrice">
                            <el-input v-model.trim="dataSource.CostPrice">
                                <template slot="append">
                                    元
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item>
                        <el-col :span="3" class="form-title"><span style="color:red;">*</span>批准文号：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="ProductExtend.ApprovalNumber" :rules="productInfoValidate.ProductExtend.ApprovalNumber">
                                <el-input v-model.trim="dataSource.ProductExtend.ApprovalNumber">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item> 
                <el-form-item>
                        <el-col :span="3" class="form-title"><span style="color:red;">*</span>剂型：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="DosageForm">
                                <DictionarySelect v-model="dataSource.ProductExtend.DosageForm" dicTypeCode="DosageFormTypeCode"></DictionarySelect>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title"><span style="color:red;">*</span>批准文号：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="ApprovalNumber">
                                <el-input v-model.trim="dataSource.ProductExtend.ApprovalNumber" :maxlength="100" :minlength="1">
                                </el-input>
                            </el-form-item>
                        </el-col>

                    </el-form-item>
                    <el-form-item>
                        <el-col :span="3" class="form-title">是否双人质检：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsDoubleQc">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsDoubleCheck"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">重点商品：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsImportant">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsImportant"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">是否特殊药品：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="isSpecialDrug">
                                <el-switch v-model.trim="dataSource.ProductExtend.isSpecialDrug"></el-switch>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="3" class="form-title">是否重点养护：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsImportantProtect">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsImportantProtect"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">是否采集药监码：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsSupervise">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsSupervise"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">是否蛋白同化制剂和肽类激素：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsProteinPeptide">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsProteinPeptide"></el-switch>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>

                        <el-col :span="3" class="form-title">是否进口药：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsImport">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsImport"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title"><span style="color:red;">*</span>上市许可持有人：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="ListingPermitHolder">
                                <el-input v-model.trim="dataSource.ProductExtend.ListingPermitHolder" :maxlength="100" :minlength="1">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="3" class="form-title">是否易变：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsEasyChange">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsEasyChange"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">是否首营：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsFirstSale">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsFirstSale"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="form-title">是否贵重：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsExpensive">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsExpensive"></el-switch>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="3" class="form-title">是否需要药检报告书：</el-col>
                        <el-col :span="4">
                            <el-form-item prop="IsNeedCheckReport">
                                <el-switch v-model.trim="dataSource.ProductExtend.IsNeedCheckReport"></el-switch>
                            </el-form-item>
                        </el-col>
                    </el-form-item> -->
            </el-tab-pane>
            <el-tab-pane label="自定义属性" name="attribut">
                <h3 class="header_p">自定义属性</h3>
                <div class="h-handle-button">
                    <div class="h-b">
                        <el-button :disabled="dataSource.ProductStatus==100" type="primary" size="small" @click="addAttribute">添加商品属性</el-button>
                    </div>
                </div>
                <el-table :data="dataSource.Attributes" border highlight-current-row>
                    <el-table-column prop="ProductAttributeName" label="属性名称">
                        <template slot-scope="scope">
                            <el-input :disabled="dataSource.ProductStatus==100" v-model.trim="scope.row.ProductAttributeName" size="mini" placeholder="属性名称" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="ProductAttributeValue" label="属性值">
                        <template slot-scope="scope">
                            <el-input :disabled="dataSource.ProductStatus==100" v-model.trim="scope.row.ProductAttributeValue" size="mini" placeholder="属性值" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="ProductAttributeMemo" label="属性备注">
                        <template slot-scope="scope">
                            <el-input :disabled="dataSource.ProductStatus==100" v-model.trim="scope.row.ProductAttributeMemo" size="mini" placeholder="属性备注" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="SortValue" label="排序">
                        <template slot-scope="scope">
                            <el-input :disabled="dataSource.ProductStatus==100" type="number" oninput="this.value=this.value.replace(/[^0-9]+/,'');" v-model.trim.number="scope.row.SortValue" size="mini" placeholder="排序" />
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100px">
                        <template slot-scope="scope">
                            <el-button :disabled="dataSource.ProductStatus==100" @click="removeAttribute(scope.row)" size="small" type="text">移除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="多包装管理" name="unitInfo">
                <h3 class="header_p">商品包装</h3>
                <div v-if="activeName==='unitInfo'">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="addUnit">添加多包装信息</el-button><!--:disabled="dataSource.ProductStatus==100"-->
                        </div>
                    </div>
                    <el-table :data="dataSource.UnitList" border highlight-current-row>
                        <el-table-column prop="IsPrimary" label="是否主单位">
                            <template slot-scope="scope">
                                <el-switch v-model.trim="scope.row.IsPrimary" @change="isPrimaryChange(scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ProductUnitCategory" label="包装级别">
                            <template slot-scope="scope">
                                <DictionarySelect v-model="scope.row.ProductUnitCategory" dicTypeCode="ProductPackageCode" datType="Int"></DictionarySelect>
                            </template>
                        </el-table-column>
                        <el-table-column prop="PackageSpec" label="包装规格">
                            <template slot-scope="scope">
                                <el-input  v-model.trim="scope.row.PackageSpec" size="mini" placeholder="包装规格" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="PackingNo" label="包装单位">
                            <template slot-scope="scope">
                                <el-input  v-model.trim="scope.row.PackingNo" size="mini" placeholder="包装单位" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="PackageNum" label="包装数量">
                            <template slot-scope="scope">
                                <el-input  v-model.trim="scope.row.PackageNum" type="number" oninput="this.value=this.value.replace(/[^0-9.]+/,'');" size="mini" placeholder="包装数量" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="IsUnpacking" label="是否拆包">
                            <template slot-scope="scope">
                                <el-switch v-model.trim="scope.row.IsUnpacking"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ProductUnitName" label="单位名称">
                            <template slot-scope="scope">
                                <el-input :disabled="scope.row.IsPrimary" v-model.trim="scope.row.ProductUnitName" size="mini" placeholder="单位名称" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="RelationToPrimary" label="包装率" width="120">
                            <template slot-scope="scope">
                                <el-input :disabled="scope.row.IsPrimary" oninput="this.value=this.value.replace(/[^0-9]+/,'');" v-model.trim.number="scope.row.RelationToPrimary" :maxlength="6" size="mini" placeholder="包装率" />
                                <!--<el-col :span="7">
                                        <el-input :disabled="true" size="mini" placeholder="1" />
                                    </el-col>
                                    <el-col :span="6">
                                        <el-input :disabled="true" size="mini" placeholder="=" />
                                    </el-col>
                                    <el-col :span="11">
                                        <el-input :disabled="dataSource.ProductStatus==100 || scope.row.IsPrimary" oninput="this.value=this.value.replace(/[^0-9]+/,'');" v-model.trim.number="scope.row.RelationToPrimary" :maxlength="6" size="mini" />
                                    </el-col>-->
                            </template>
                        </el-table-column>
                        <el-table-column prop="PackageBarCode" label="包装条码">
                            <template slot-scope="scope">
                                <el-input  v-model.trim="scope.row.PackageBarCode" size="mini" placeholder="包装条码" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="GrossWeight" label="毛重(kg)">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.GrossWeight" type="number"  size="mini" placeholder="毛重(kg)" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="NetWeight" label="净重(kg)">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.NetWeight" type="number" size="mini" placeholder="净重(kg)" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="ProductVolume" label="体积(cm³)">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.PackageVolume" type="number" size="mini" placeholder="体积(cm³)" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="PackageLong" label="长(cm)">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.PackageLong" type="number" size="mini" placeholder="长(cm)" /><!-- oninput="this.value=this.value.replace(/[^0-9.]+/,'');"-->
                            </template>
                        </el-table-column>
                        <el-table-column prop="PackageWidth" label="宽(cm)">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.PackageWidth" type="number" size="mini" placeholder="宽(cm)" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="PackageHeight" label="高(cm)">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.PackageHeight" type="number" size="mini" placeholder="高(cm)" />
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="50">
                            <template slot-scope="scope">
                                <el-button :disabled="scope.row.IsPrimary" @click="removeUnit(scope.row)" size="small" type="text">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane label="商品图片">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        商品封面图：
                    </el-col>
                    <el-col :span="17">
                        <el-upload :action="uploadUrl" ref="coverupload" :file-list="dataSource.CoverFileList" :auto-upload="true" accept=".jpg,.png" :data="fileData" :on-success="coverUploadSuccess" list-type="picture-card" :on-change="coverChange" :before-upload="beforeCoverUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        商品细节图：
                    </el-col>
                    <el-col :span="17">
                        <span>建议单张大小不超过2M，最多上传10张</span>
                        <el-upload :action="uploadUrl" ref="imageDetailUpload" :file-list="dataSource.DetailFileList" :auto-upload="true" accept=".jpg,.png" :data="fileData" :on-success="uploadSuccess" multiple list-type="picture-card" :on-change="detailChange" :before-upload="beforeFileUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-col>
                </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="商品文件">
                <el-form-item>
                    <el-col :span="24">
                        <el-upload :action="uploadUrl" ref="upload" :show-file-list="false" :file-list="dataSource.FileList" :auto-upload="true" accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4" :data="productFileData" :on-success="attachmentUploadSuccess" :limit="10" :on-exceed="uploadExceed" :before-upload="uploadBefore" class="el-fileUpload">
                            <div slot="tip" class="el-upload-tip">
                                附件列表(支持.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4文件，且不超过50MB)
                            </div>
                            <el-button type="primary" size="small" class="el-upload-btn">上传附件</el-button>
                        </el-upload>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-table :data="dataSource.FileList" border style="width: 100%">
                        <el-table-column prop="FileName" label="文件名">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button type="primary" v-show="isImage(scope.row['FileName'])" size="small" style="margin-left:10px;" @click="uploadShowImage(scope.row)">查看
                                </el-button>
                                <el-button type="primary" size="small" style="margin-left:10px;" @click="uploadDownload(scope.row)">下载
                                </el-button>
                                <el-button type="primary" size="small" style="margin-left:10px;" @click="uploadRemove(scope.row)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="作用店铺" name="shop">
                <span style="color:red;">*</span>
                <el-switch v-model.trim="dataSource.IsLimitUseShop" inactive-text="限制可用店铺"></el-switch>
                <h3 class="header_p">作用店铺</h3>
                <div v-if="activeName==='shop'">
                    <el-table :data="dataSource.ShopInfos" :default-sort="{prop: 'ShopNo', order: 'descending'}" border highlight-current-row>
                        <el-table-column label="" width="50" align="center">
                            <template slot-scope="scope">
                                <el-checkbox v-model.trim="scope.row.isChecked" :disabled="!dataSource.IsLimitUseShop"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ShopNo" label="店铺编码" sortable align="center">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ShopNo">{{scope.row.ShopNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ShopName" label="店铺名称" sortable align="center">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ShopName">{{scope.row.ShopName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ShopContactName" label="交易平台" sortable align="center">
                            <template slot-scope="scope">
                                <span v-model.trim="scope.row.ShopContactName">{{scope.row.ShopContactName}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane label="日志信息" name="tabsProductLogs">
                <el-table :data="dataSource.ProductLogs" border highlight-current-row>
                    <el-table-column prop="OperateTime" label="操作日期">
                        <template slot-scope="scope">
                            <span>{{scope.row.OperateTime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OperateUserName" label="操作人">
                        <template slot-scope="scope">
                            <span>{{scope.row.OperateUserName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OperateDesc" label="操作描述">
                        <template slot-scope="scope">
                            <span>{{scope.row.OperateDesc}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DisplayPlatformType" label="操作来源">
                        <template slot-scope="scope">
                            <span>{{scope.row.DisplayPlatformType}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </el-form>
    <el-dialog title="附件查看" :visible.sync="uploadConfig.isShowImage" size="large" :modal-append-to-body="false" top="8%" width="50%">
        <template v-slot:title>
        </template>
        <img @click="uploadConfig.isShowImage = false" style="width:100%;" :src="uploadConfig.showImage">
    </el-dialog>
</div>
</template>

<script>
import Template from '../../../system/template/components/template.vue';
export default {
    data() {
        var checkRelationToPrimary = function (rule, value, callback) {
            if (!value || value <= 0) return callback(new Error('换算关系必须大于0'));
            callback();
        };
        return {
            //actionurl: this.Utils.getDomain() + "omsapi/common/uploadsinglefile?currentType=" + this.$store.state.global.currentType + "&currentCCode=" + this.$store.state.global.currentCCode,
            uploadConfig: {
                fileType: 800,
                isShowImage: false, //查看图片
                showImage: ''
            },
            productFileList: [],
            activeName: 'first',
            unit: '',
            loadding: false,
            //importAction: this.Utils.getDomain() + "omsapi/common/uploadpicdata",
            uploadUrl: window.AppConfig.apiCustomerUrl + "omsapi/files/upload?currentType=" + this.$store.state.global.currentType + "&currentCCode=" + this.$store.state.global.currentCCode + "&token=" + this.Utils.getToken(),
            fileData: {
                FileType: 500
            },
            productFileData: {
                FileType: 502
            },
            isDisabled: false,
            fileList: [],
            coverFileList: [],
            ProductUnitCategorys: [{
                    label: '内包装',
                    value: 2,
                    status: true
                },
                {
                    label: '箱',
                    value: 3,
                    status: true
                },
                {
                    label: '托盘',
                    value: 4,
                    status: true
                }
            ],
            ABCTypeList: [{
                    label: '--请选择ABC类型--',
                    value: 0
                },
                {
                    label: 'A',
                    value: 1
                },
                {
                    label: 'B',
                    value: 2
                },
                {
                    label: 'C',
                    value: 3
                }
            ],
            classfiySelectOptions: [],
            productInfoValidate: {
                ProductName: [{
                    required: true,
                    message: '请输入商品名称',
                    trigger: 'blur'
                }],
                ProductWholeUnit: [{
                        required: true,
                        message: '请输入商品主单位',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 3,
                        message: '长度在 1 到 3 个字符',
                        trigger: 'blur'
                    }
                ],
                RelationToPrimary: [{
                    validator: checkRelationToPrimary,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: '换算关系不能为空'
                }, {
                    type: 'number',
                    message: '换算关系必须为数字值'
                }],
                ProductExtend: {}
            },
            extendRules: {

            }
        }
    },
    computed: {},
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    mounted() {
        this.Event.$on("clearEditProductInfoForm", () => this.resetForm);
        this.productCategoryTreeFn();
    },
    watch: {
        dataSource: {
            handler: function (newval, oldval) {},
            deep: true //对象内部的属性监听，也叫深度监听
        },
        'dataSource.ConfigList': {
            handler(curVal, oldVal) {
                var list = curVal;
                if (list && list.length > 0) {
                    list.forEach(item => {
                        if (item.Required)
                            this.productInfoValidate.ProductExtend[item.FieldName] = [{
                                required: true,
                                message: '不能为空',
                                trigger: 'blur'
                            }];
                    });
                }
            },
            deep: true
        },
    },
    methods: {
        customerChange() {},
        addProductInfo() {
            this.Event.$emit("onAddProductInfo");
        },
        isPrimaryChange(row){
        },
        resetForm() {
            !this.isPassValidate && this.$refs['_productInfoForm'].resetFields(); //清空表单
        },
        productCategoryTreeFn() {
            var _this = this;
            this.$ajax.query("omsapi/productclassify/trees", "get", {}, (data) => {
                if (data.IsSuccess) {
                    _this.classfiySelectOptions = _this.Utils.iterationDelateMenuChildren(data.Result);
                    _this.treeVal = data.Result;
                } else {
                    _this.Utils.messageBox("获取数据失败.", "error");
                }
            });
        },
        VolumeCalculation() {
            if (this.dataSource.ProductLong != '' && this.dataSource.ProductLong != null && this.dataSource.ProductLong != undefined &&
                this.dataSource.ProductWidth != '' && this.dataSource.ProductWidth != null && this.dataSource.ProductWidth != undefined &&
                this.dataSource.ProductHeight != '' && this.dataSource.ProductHeight != null && this.dataSource.ProductHeight != undefined) {
                var m = 0,
                    s1 = this.dataSource.ProductLong.toString(),
                    s2 = this.dataSource.ProductWidth.toString(),
                    s3 = this.dataSource.ProductHeight.toString();
                try {
                    m += s1.split(".")[1].length
                } catch (e) {}
                try {
                    m += s2.split(".")[1].length
                } catch (e) {}
                try {
                    m += s3.split(".")[1].length
                } catch (e) {}
                this.dataSource.ProductVolume = Number(s1.replace(".", "")) * Number(s2.replace(".", "")) * Number(s3.replace(".", "")) / Math.pow(10, m);
            }
        },
        saveProductInfo() {
            this.$refs["_productInfoForm"].validate((valid) => {
                var data = this.dataSource;
                if (valid) {
                    var flag = true;
                    //if (this.dataSource.CoverFileList.length > 0) {
                    //    flag = false;
                    //    console.log("图片上传");
                    //    this.$refs.coverupload.submit();
                    //}
                    //if (this.dataSource.DetailFileList.length > 0) {
                    //    flag = false;
                    //    console.log("图片上传");
                    //    this.$refs.upload.submit();
                    //}
                    if (flag) {
                        this.commitProductInfo();
                    }
                } else {
                    return false;
                }
            });
        },
        commitProductInfo() {
            //价格脱敏处理
            var flag = this.priceSensitive();
            if (!flag) {
                return false;
            }
            var units = [];
            for (var i = 0; i < this.dataSource.UnitList.length; i++) {
                if (!this.dataSource.UnitList[i].ProductUnitName) {
                    this.Utils.messageBox("辅助单位名称不能为空", "error");
                    return false;
                }
                // if (this.dataSource.UnitList[i].ProductUnitName == this.unit) {
                //     this.Utils.messageBox("辅助单位不能与商品单位相同", "error");
                //     return false;
                // }
                if (this.dataSource.UnitList[i].RelationToPrimary == null || this.dataSource.UnitList[i].RelationToPrimary == "") {
                    this.Utils.messageBox("换算关系不能为空", "error");
                    return false;
                }
                if (!this.Utils.isRegularNumber(this.dataSource.UnitList[i].RelationToPrimary) || this.dataSource.UnitList[i].RelationToPrimary <= 0) {
                    this.Utils.messageBox("换算关系只能是数字，并且大于0", "error");
                    return false;
                }
                if (this.dataSource.UnitList[i].ProductUnitCategory != null) {
                    units.push(this.dataSource.UnitList[i]);
                }
            }
            this.dataSource.UnitList = units;
            var routeName = this.dataSource.ProductID == 0 ? "create" : "save";
            this.dataSource.ImageSysAttachments = [];
            this.dataSource.FileSysAttachments = [];
            var coverFileList = this.$refs.coverupload.uploadFiles;
            console.log(this.$refs.coverupload);
            for (var i = 0; i < coverFileList.length; i++) {
                var file = coverFileList[i];
                if (file.status === "success") {
                    var attachment = {
                        FileTitle: file.name,
                        AttachmentType: 500,
                        AttachmentUrl: (file.response ? file.response.Result.Url : file.url),
                        AttachmenCategory: 500,
                        FileName: file.name
                    };
                    this.dataSource.ImageSysAttachments.push(attachment);
                }

            }
            var detailImageList = this.$refs.imageDetailUpload.uploadFiles;
            for (var i = 0; i < detailImageList.length; i++) {
                var file = detailImageList[i];
                if (file.status === "success") {
                    var attachment = {
                        FileTitle: file.name,
                        AttachmentType: 500,
                        AttachmentUrl: (file.response ? file.response.Result.Url : file.url),
                        AttachmenCategory: 501,
                        FileName: file.name
                    };
                    this.dataSource.ImageSysAttachments.push(attachment);
                }
            }
            var fileList = this.$refs.upload.uploadFiles;
            console.log(fileList);
            for (var i = 0; i < fileList.length; i++) {
                var file = fileList[i];
                if (file.status === "success") {
                    var attachment = {
                        FileTitle: file.FileName,
                        AttachmentType: 500,
                        AttachmentUrl: file.AttachmentUrl,
                        AttachmenCategory: 502,
                        FileName: file.FileName
                    };
                    this.dataSource.FileSysAttachments.push(attachment);
                }
            }
            this.$ajax.send("omsapi/product/" + routeName, "post", this.dataSource, (data) => {
                this.dataSource.ProductID === this.Utils.emptyGuid && (this.dataSource.ProductID = data.Result);
                this.$parent.syncDataSource();
                this.Event.$emit("reloadProductInfoPageList", data.Result);
                this.Utils.messageBox("保存成功.", "success");

            });
        },
        //价格脱敏处理
        priceSensitive() {
            var flag = false;
            var _this = this;
            if (_this.dataSource.IsPriceSensitive) {
                //采购价
                if (_this.dataSource.PurchasePrice != "" && _this.dataSource.PurchasePrice != null && _this.dataSource.PurchasePrice != "****" && !_this.Utils.isRegularNumber(_this.dataSource.PurchasePrice)) {
                    _this.Utils.messageBox("加密的采购价只能为大于0数字", "error");
                    return false;
                }
                if (_this.dataSource.PurchasePrice == "****") {
                    _this.dataSource.PurchasePrice = _this.dataSource.originalPurchasePrice;
                }
                //成本价
                if (_this.dataSource.CostPrice != "" && _this.dataSource.CostPrice != null && _this.dataSource.CostPrice != "****" && !_this.Utils.isRegularNumber(_this.dataSource.CostPrice)) {
                    _this.Utils.messageBox("加密的成本价只能为大于0数字", "error");
                    return false;
                }
                if (_this.dataSource.CostPrice == "****") {
                    _this.dataSource.CostPrice = _this.dataSource.originalCostPrice;
                }
                //销售价
                if (_this.dataSource.SalePrice != "" && _this.dataSource.SalePrice != null && _this.dataSource.SalePrice != "****" && !_this.Utils.isRegularNumber(_this.dataSource.SalePrice)) {
                    _this.Utils.messageBox("加密的销售价只能为大于0数字", "error");
                    return false;
                }
                if (_this.dataSource.SalePrice == "****") {
                    _this.dataSource.SalePrice = _this.dataSource.originalSalePrice;
                }
                flag = true;
            } else {
                if (_this.dataSource.PurchasePrice != "" && _this.dataSource.PurchasePrice != null && !_this.Utils.isRegularNumber(_this.dataSource.PurchasePrice)) {
                    _this.Utils.messageBox("采购价只能为大于0数字", "error");
                    return false;
                }
                if (_this.dataSource.CostPrice != "" && _this.dataSource.CostPrice != null && !_this.Utils.isRegularNumber(_this.dataSource.CostPrice)) {
                    _this.Utils.messageBox("成本价只能为大于0数字", "error");
                    return false;
                }
                if (_this.dataSource.SalePrice != "" && _this.dataSource.SalePrice != null && !_this.Utils.isRegularNumber(_this.dataSource.SalePrice)) {
                    _this.Utils.messageBox("销售价只能为大于0数字", "error");
                    return false;
                }
                flag = true;
            }
            return flag;
        },
        classifyrChange(code) {
            if (code == "0") {
                this.dataSource.ProductTypeCode = "0";
                this.dataSource.ProductTypeName = "";
            } else {
                for (var i = 0; i < this.dataSource.ProductClassifys.length; i++) {
                    var classify = this.dataSource.ProductClassifys[i];
                    if (classify.Id == code) {
                        this.dataSource.ProductTypeCode = classify.Id;
                        this.dataSource.ProductTypeName = classify.Name;
                    }
                }
            }
        },
        beforeCoverUpload(file) {
            const picType = (file.type === 'image/jpeg') || (file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 2;
            console.log("校验图片类型");
            if (!picType) {
                this.$message.error('上传图片只能是 JPEG和PNG 格式!');
                for (var i = 0; i < this.dataSource.CoverFileList.length; i++) {
                    if (this.dataSource.CoverFileList[i].uid === file.uid) this.dataSource.CoverFileList.splice(i, 1);
                }
            }
            console.log("校验图片大小");
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
                for (var i = 0; i < this.dataSource.CoverFileList.length; i++) {
                    if (this.dataSource.CoverFileList[i].uid === file.uid) this.dataSource.CoverFileList.splice(i, 1);
                }
            }
            return picType && isLt2M;
        },
        beforeFileUpload(file) {
            const picType = (file.type === 'image/jpeg') || (file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 2;
            console.log("校验图片类型");
            if (!picType) {
                this.$message.error('上传图片只能是 JPEG和PNG 格式!');
                for (var i = 0; i < this.dataSource.DetailFileList.length; i++) {
                    if (this.dataSource.DetailFileList[i].uid === file.uid) this.dataSource.DetailFileList.splice(i, 1);
                }
            }
            console.log("校验图片大小");
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
                for (var i = 0; i < this.dataSource.DetailFileList.length; i++) {
                    if (this.dataSource.DetailFileList[i].uid === file.uid) this.dataSource.DetailFileList.splice(i, 1);
                }
            }
            return picType && isLt2M;
        },
        uploadSuccess(response, file, fileList) {
            console.log("uploadSuccess");
            console.log(response);
            console.log(file);
            console.log(fileList);
        },
        coverUploadSuccess(response, file, fileList) {
            console.log("coverUploadSuccess");
            console.log(response);
            console.log(file);
            console.log(fileList);
        },
        coverChange(file, fileList) {
            this.dataSource.CoverFileList = fileList.slice(-1);
        },
        detailChange(file, fileList) {
            this.dataSource.DetailFileList = fileList.slice(-10);
        },
        attachmentUploadSuccess(response, file, fileList) {
            console.log("attachmentUploadSuccess");
            console.log(response);
            console.log(file);
            console.log(fileList);
            if (response.IsSuccess) {
                var count = this.dataSource.FileList.length;
                var result = response.Result;
                var model = {
                    AttachmentUrl: result.Url,
                    FileName: result.FileName,
                    Sort: count
                };
                if (response.Result) {
                    this.dataSource.FileList.push(model);
                } else {
                    this.$refs.upload.uploadFiles.splice(-1, 1);
                    this.$message.error(response.OperationDesc);
                }
            }
        },
        uploadExceed(files, fileList) {
            this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        uploadBefore(file) {
            console.log(file);
            const picType = (file.type === 'image/jpeg') || (file.type === 'image/png') || (file.type === 'video/mp4') ||
                (file.type === 'application/msword') || (file.type === 'application/vnd.ms-excel') ||
                (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
                (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
                (file.type === 'text/plain') || (file.name.endsWith('.xls')) || (file.name.endsWith('.xlsx'));
            const isLt50M = file.size / 1024 / 1024 < 50;
            console.log("校验类型");
            if (!picType) {
                this.$message.error('上传文件只能是.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.txt,.mp4格式!');
            }
            console.log("校验大小");
            if (!isLt50M) {
                this.$message.error('上传文件大小不能超过 50MB!');

            }
            return picType && isLt50M;
        },
        isImage(fileName) {
            //获取最后一个.的位置
            var index = fileName.lastIndexOf(".");
            //获取后缀
            var ext = fileName.substr(index + 1);
            return ['png', 'jpg', 'jpeg'].indexOf(ext.toLowerCase()) !== -1;
        },
        uploadShowImage(row) {
            var url = row.AttachmentUrl;
            if (url) {
                this.uploadConfig.showImage = url;
                this.uploadConfig.isShowImage = !this.isShowImage;
            }
        },
        uploadDownload(row) {
            var url = row.AttachmentUrl;
            if (url) {
                this.Utils.exportExt(url);
            }
        },
        uploadRemove(row) {
            var index = row.Sort;
            this.dataSource.FileList.splice(index, 1);
            this.$refs.upload.uploadFiles.splice(index, 1);
        },
        //商品属性
        addAttribute: function () {
            var _this = this;
            if (!_this.dataSource.Attributes) return;

            var newRow = {
                ProductAttributeName: null,
                ProductAttributeValue: null,
                ProductAttributeMemo: null,
                SortValue: 1,
                IsSku: false
            };
            _this.dataSource.Attributes.push(newRow);
        },
        removeAttribute: function (row, index) {
            this.dataSource.Attributes.remove(row);
        },
        //商品包装
        addUnit: function () {
            var _this = this;
            _this.unit = _this.dataSource.ProductWholeUnit;
            console.log(_this.unit);
            if (_this.unit == null) {
                _this.activeName = 'first';
                this.Utils.messageBox("请输入商品主单位");
                return;
            }
            if (_this.dataSource.UnitList.length == 3) {
                this.Utils.messageBox("商品包装最多只能有3项");
                return;
            }
            if (!_this.dataSource.UnitList) return;
            var newRow = {
                ProductUnitCategoryName: "",
                ProductUnitCategory: null,
                ProductUnitName: null,
                PackageLong: 0,
                PackageWidth: 0,
                PackageHeight: 0,
                GrossWeight: 0,
                NetWeight: 0
            };
            _this.dataSource.UnitList.push(newRow);
        },
        InitProductUnitCategorys() {
            this.unit = this.dataSource.ProductWholeUnit;
            if (this.unit == null) {
                this.activeName = 'first';
                this.Utils.messageBox("请输入商品主单位");
                return;
            }
            if (this.dataSource.UnitList.length == 0) {
                this.ProductUnitCategorys = [{
                        label: '内包装',
                        value: 2,
                        status: true
                    },
                    {
                        label: '箱',
                        value: 3,
                        status: true
                    },
                    {
                        label: '托盘',
                        value: 4,
                        status: true
                    }
                ];
            }
            for (var i = 0; i < this.ProductUnitCategorys.length; i++) {
                var isSelect = false;
                for (var j = 0; j < this.dataSource.UnitList.length; j++) {
                    if (this.dataSource.UnitList[j].ProductUnitCategory == this.ProductUnitCategorys[i].value) {
                        isSelect = true;
                        continue;
                    }
                    this.ProductUnitCategorys[i].status = true;
                }
                if (isSelect) {
                    this.ProductUnitCategorys[i].status = false;
                }
            }
        },
        //changeProductUnitCategorys: function (row) {
        //    for (var j = 0; j < this.dataSource.UnitList.length; j++) {
        //        if (row.ProductUnitCategoryName != this.dataSource.UnitList[j].ProductUnitCategoryName) {
        //            continue;
        //        }
        //        for (var i = 0; i < this.ProductUnitCategorys.length; i++) {
        //            if (this.ProductUnitCategorys[i].value == this.dataSource.UnitList[j].ProductUnitCategoryName) {
        //                this.dataSource.UnitList[j].ProductUnitCategory = this.ProductUnitCategorys[i].value;
        //                this.dataSource.UnitList[j].ProductUnitCategoryName = this.ProductUnitCategorys[i].label;
        //            }
        //        }
        //    }
        //},
        removeUnit: function (row, index) {
            this.dataSource.UnitList.remove(row);
        },
    },
    components: {
        Template
    }
}
</script>

<style>
input[type=file] {
    display: none;
}

.extend-title,
.extend-content {
    line-height: 24px;
    margin-bottom: 6px;
}
</style>
