<template>
    <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
        <div class="h-handle-button" v-show="!config.isDetailDisplay" slot="buttonGroup">
            <div class="h-b">
                <el-button type="primary" size="small" @click="addIETemplate" v-right-code="'Template:Addoredit'">新增</el-button>
            </div>
            <div class="h-b">
                <el-button type="primary" size="small" @click="delIETemplate" v-right-code="'Template:Removetemplates'">删除</el-button>
            </div>
        </div>
        <el-table slot="elList" ref="roleListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
            <el-table-column type="selection" width="33" v-if="!config.isDetailDisplay"></el-table-column>
            <el-table-column v-for="(col,index) in dataSource.ColDefs.BodyFieldParams"
                             :key="index"
                             :prop="col.FieldName"
                             :label="col.DisplayName"
                             :render-header="bindFilter(queryParam,col)"
                             :fixed="index==0&&(!config||!config.isDetailDisplay)"
                             :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                             v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                <template slot-scope="scope">
                    <span v-if="col.FieldName==='Name'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                    <span v-else>  {{ scope.row[col.FieldName] }}</span>
                </template>
            </el-table-column>
        </el-table>
    </fixed-list>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("onRefresh", () => this.reloadPageList());
                this.Event.$on("onAdd", () => this.addIETemplate());
            });
            this.initialize();
        },
        data(){
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {
                      BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                }
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            }
        },
        methods: {
            reloadPageList() {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            onPageChange(param) {
                this.queryParam = param;
                this.$ajax.send("omsapi/template/getalltemplates", "post", param, data => {
                    this.dataSource = data;
                });
            },
            onDataSourceChange(ds)
            {
                var _this = this;

                    _this.dataSource = {
                        ColDefs: {
                            BodyFieldParams: []
                        },
                        Result: [],
                        TotalCount: 0
                };
                    _this.$nextTick(function () {
                        _this.dataSource = ds;

                    });


            },
            rowClick(row) {
                this.$ajax.send("omsapi/template/search", "get", { id: row.TemplateID }, (data) => {
                    console.log(data.Result.Items);
					if(data.Result.ImportExportType){
					  data.Result.ImportExportType=data.Result.ImportExportType+'';
					}
					if(data.Result.BusinessType){
						data.Result.BusinessType=data.Result.BusinessType+'';
					}
                    this.onChangeEditDataSource(data.Result);
                });
            },
            addIETemplate() {
                var parent = this.$parent;
                if (parent.operatorType && parent.operatorType.length > 0 && parent.businessType && parent.businessType.length > 0)
                {
                    this.$ajax.send("omsapi/template/getemptytemplate", "get", {
                        importExportType:  parent.operatorType[0].Key,
                        businessType: parent.businessType[0].Key
                    }, (data) => {
						if(data.Result.ImportExportType){
						  data.Result.ImportExportType=data.Result.ImportExportType+'';
						}
						if(data.Result.BusinessType){
							data.Result.BusinessType=data.Result.BusinessType+'';
						}
						
                        this.onChangeEditDataSource(data.Result);
                    });
                }
                else
                    this.Utils.messageBox(`获取模板数据错误,请重新打开页面.`, "error");
            },
            delIETemplate() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择要删除的模板.`, "error");
                    return false;
                }
				
				var ids=this.Utils.selectionsToArr(this.multipleSelection,"TemplateID");

                var This = this;
                this.Utils.confirm({
                    title:"是否确认删除模板?",
                    content:"删除模板后数据不可恢复,该模板将不可使用,是否确认删除?"
                },()=>{
                    this.$ajax.send("omsapi/template/removetemplates", "post", ids, (data) => {
                       This.onPageChange(This.queryParam);
                       This.Utils.messageBox("模板删除成功.","success");
                    });
                },()=>{
                    This.Utils.messageBox("取消模板删除.","info");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            }
        }
    }
</script>
<style>

</style>